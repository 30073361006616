import React, { useState } from 'react';
import a from './SalePage.module.scss';
import Block1 from "./Block1";
import Block2 from "./Block2";
import ProgrammBlock from "./ProgrammBlock";
import ModalWindow from "./ModalWindow";
import Block4 from "./Block4";
import Block5 from "./Block5";
import Tariffs from "./Tariffs";
import CasesReviews from "./CasesReviews";
import Footer1 from "../Components/FooterWhite";
import SupportBlock from "./SupportBlock";
import ModalWindowCase from "./ModalWindowСase";

const SalePage = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedModuleId, setSelectedModuleId] = useState(null);
    const [selectedCaseId, setSelectedCaseId] = useState(null);

    const handleOpenModal = (moduleId) => {
        setSelectedModuleId(moduleId);
        setIsModalOpen(true);
    };

    const handleOpenModalCase = (caseId) => {
        setSelectedCaseId(caseId);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedModuleId(null);
        setSelectedCaseId(null);
    };

    return (
        <div className={a.wrapper}>

            <Block1 />
            <Block2 />
            <ProgrammBlock onOpenModal={handleOpenModal} />
            {isModalOpen && (
                <ModalWindow moduleId={selectedModuleId} onClose={handleCloseModal} />
            )}
            <Block4/>
            <Block5/>
            <Tariffs/>
            <CasesReviews onOpenModalCase={handleOpenModalCase}/>
            {isModalOpen && (
                <ModalWindowCase caseId={selectedCaseId} onClose={handleCloseModal} />
            )}
            <SupportBlock/>
            <Footer1/>
        </div>
    );
};

export default SalePage;
