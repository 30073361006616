import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from "axios";

const SuccessPage = () => {
    const navigate = useNavigate();

    const sendFormDataToServer = async (formData) => {
        try {
            // Отправьте данные формы на сервер
            const response = await axios.post(`/api/sendFormData`, formData);
            if (response.status === 200) {
                // Данные успешно отправлены
                console.log('Данные успешно отправлены');
            } else {
                // Произошла ошибка при отправке
                console.error('Произошла ошибка при отправке данных');
            }
        } catch (error) {
            console.error('Произошла ошибка', error);
        }
    };

    useEffect(() => {
        // Получите данные формы из localStorage (предположим, что они были сохранены там при отправке формы)
        const formData = JSON.parse(localStorage.getItem('formData'));

        // Проверьте, что есть данные формы и вызовите функцию sendFormDataToServer
        if (formData) {
            sendFormDataToServer(formData);
        }

        // Очистите данные формы из localStorage
        localStorage.removeItem('formData');

        // Выполните программную навигацию на этой странице
        navigate('/success'); // Навигация на текущей странице
    }, [navigate]);

    return (
        <div className='SuccessWrapper'>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="120"
                height="120"
                viewBox="0 0 120 120"
            >
                {/* Круг */}
                <circle cx="60" cy="60" r="55" fill="#4CAF50" />

                {/* Галочка */}
                <path
                    fill="#FFF"
                    d="M96.177 25.823a5 5 0 0 0-7.07 0L50 67.071 30.893 47.964a5 5 0 0 0-7.07 7.071l27.072 27.071a5 5 0 0 0 7.071 0l38.89-38.89a5 5 0 0 0 0-7.072z"
                />

            </svg>

            <h2 style={{ color: '#007BFF' }}>Успешно!</h2>
            <p style={{ color: '#007BFF' }}>
                Ваши данные успешно отправлены. Мы свяжемся с вами в ближайшее время.
            </p>
        </div>
    );
};

export default SuccessPage;
