import React, {useRef, useState, useEffect} from 'react';
import b from './SalePage.module.scss';
import styles from "./SalePage.module.scss";
import {Fade} from "react-awesome-reveal";

const Block5 = () => {
    const scrollRef = useRef(null);
    const [centerIndex, setCenterIndex] = useState(0);

    const cardData = [
        {id: 1, backgroundImage: 'url(/new/01.png)'},
        {id: 2, backgroundImage: 'url(/new/02.png)'},
        {id: 3, backgroundImage: 'url(/new/03.png)'},
        {id: 4, backgroundImage: 'url(/new/04.png)'},
        {id: 5, backgroundImage: 'url(/new/05.png)'},
        {id: 6, backgroundImage: 'url(/new/06.png)'},
        {id: 7, backgroundImage: 'url(/new/07.png)'},
        {id: 8, backgroundImage: 'url(/new/08.png)'},
    ];

    // Дублируем данные для создания эффекта бесконечного скролла
    const infiniteData = [...cardData, ...cardData, ...cardData];

    const handleScroll = () => {
        const scrollPosition = scrollRef.current.scrollLeft;
        const cardWidth = scrollRef.current.scrollWidth / infiniteData.length;

        // Определяем центральную точку контейнера
        const containerCenter = scrollRef.current.offsetWidth / 2.7;

        // Находим ближайшую карточку к центру контейнера
        const nearestIndex = Math.round((scrollPosition + containerCenter) / cardWidth) % cardData.length;
        setCenterIndex(nearestIndex);
    };

    useEffect(() => {
        const scrollEl = scrollRef.current;
        // Устанавливаем начальную позицию скролла
        scrollEl.scrollLeft = scrollEl.scrollWidth / 3;

        // Добавляем слушатель событий скролла
        scrollEl.addEventListener('scroll', handleScroll);

        return () => scrollEl.removeEventListener('scroll', handleScroll);
    }, []);

    const scrollLeft = () => {
        const cardWidth = scrollRef.current.querySelector(`.${styles.Card}`).offsetWidth; // Замените cardClassName на класс карточки
        const gap = 59; // Задайте отступ между карточками, если он есть
        scrollRef.current.scrollBy({left: -(cardWidth + gap), behavior: 'smooth'});
    };

    const scrollRight = () => {
        const cardWidth = scrollRef.current.querySelector(`.${styles.Card}`).offsetWidth; // Замените cardClassName на класс карточки
        const gap = 59; // Задайте отступ между карточками, если он есть
        scrollRef.current.scrollBy({left: cardWidth + gap, behavior: 'smooth'});
    };

    return (
        <Fade
            duration="2000"
            className={styles.block5}
        >
            <div className={b.block5}>
                <div className={b.titleControls}>
                    <h2 className={b.BlockTitles}>Превратите свои мечты в действие</h2>
                    <div className={b.controls}>
                        <button onClick={scrollLeft} className={b.scrollButton}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="8" viewBox="0 0 28 8"
                                 fill="none">
                                <path d="M28 7H2L7.65217 1" stroke="#6DC6FE" strokeWidth="1.5"/>
                            </svg>
                        </button>
                        <button onClick={scrollRight} className={b.scrollButton}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="8" viewBox="0 0 28 8"
                                 fill="none">
                                <path d="M0 7H26L20.3478 1" stroke="#6DC6FE" strokeWidth="1.5"/>
                            </svg>
                        </button>
                    </div>
                </div>

                <div className={`${b.CardWrapper} ${b.centerAlign}`} ref={scrollRef}>
                    {infiniteData.map((card, index) => (
                        <div
                            key={index}
                            className={`${b.Card} ${index % cardData.length === centerIndex ? b.active : ''}`}
                            style={{backgroundImage: card.backgroundImage}}
                        ></div>
                    ))}
                </div>
                <div className={b.MobileControls}>
                    <button onClick={scrollLeft} className={b.scrollButton}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="8" viewBox="0 0 28 8" fill="none">
                            <path d="M28 7H2L7.65217 1" stroke="#6DC6FE" strokeWidth="1.5"/>
                        </svg>
                    </button>
                    <button onClick={scrollRight} className={b.scrollButton}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="8" viewBox="0 0 28 8" fill="none">
                            <path d="M0 7H26L20.3478 1" stroke="#6DC6FE" strokeWidth="1.5"/>
                        </svg>
                    </button>
                </div>
            </div>
        </Fade>
    );
};

export default Block5;
