import React from 'react';

export const SmileBlue = () => {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="31" height="30" viewBox="0 0 31 30" fill="none">
                <path
                    d="M15.5 3.75C9.29656 3.75 4.25 8.79656 4.25 15C4.25 21.2034 9.29656 26.25 15.5 26.25C21.7034 26.25 26.75 21.2034 26.75 15C26.75 8.79656 21.7034 3.75 15.5 3.75ZM15.5 5.625C20.6891 5.625 24.875 9.81094 24.875 15C24.875 20.1891 20.6891 24.375 15.5 24.375C10.3109 24.375 6.125 20.1891 6.125 15C6.125 9.81094 10.3109 5.625 15.5 5.625ZM11.2812 11.25C10.9083 11.25 10.5506 11.3982 10.2869 11.6619C10.0232 11.9256 9.875 12.2833 9.875 12.6562C9.875 13.0292 10.0232 13.3869 10.2869 13.6506C10.5506 13.9143 10.9083 14.0625 11.2812 14.0625C11.6542 14.0625 12.0119 13.9143 12.2756 13.6506C12.5393 13.3869 12.6875 13.0292 12.6875 12.6562C12.6875 12.2833 12.5393 11.9256 12.2756 11.6619C12.0119 11.3982 11.6542 11.25 11.2812 11.25ZM19.7188 11.25C19.3458 11.25 18.9881 11.3982 18.7244 11.6619C18.4607 11.9256 18.3125 12.2833 18.3125 12.6562C18.3125 13.0292 18.4607 13.3869 18.7244 13.6506C18.9881 13.9143 19.3458 14.0625 19.7188 14.0625C20.0917 14.0625 20.4494 13.9143 20.7131 13.6506C20.9768 13.3869 21.125 13.0292 21.125 12.6562C21.125 12.2833 20.9768 11.9256 20.7131 11.6619C20.4494 11.3982 20.0917 11.25 19.7188 11.25ZM10.6363 17.8125L9.02562 18.75C10.3222 20.9878 12.7316 22.5 15.5 22.5C18.2684 22.5 20.6778 20.9878 21.9744 18.75L20.3638 17.8125C19.8722 18.6678 19.1636 19.3782 18.3096 19.872C17.4556 20.3658 16.4865 20.6256 15.5 20.625C14.5135 20.6256 13.5444 20.3658 12.6904 19.872C11.8364 19.3782 11.1278 18.6678 10.6363 17.8125Z"
                    fill="#6DC6FE"
                />
            </svg>
        </div>
    );
};

export const SmileBlack = () => {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="31" height="30" viewBox="0 0 31 30" fill="none">
                <path
                    d="M15.5 3.75C9.29656 3.75 4.25 8.79656 4.25 15C4.25 21.2034 9.29656 26.25 15.5 26.25C21.7034 26.25 26.75 21.2034 26.75 15C26.75 8.79656 21.7034 3.75 15.5 3.75ZM15.5 5.625C20.6891 5.625 24.875 9.81094 24.875 15C24.875 20.1891 20.6891 24.375 15.5 24.375C10.3109 24.375 6.125 20.1891 6.125 15C6.125 9.81094 10.3109 5.625 15.5 5.625ZM11.2812 11.25C10.9083 11.25 10.5506 11.3982 10.2869 11.6619C10.0232 11.9256 9.875 12.2833 9.875 12.6562C9.875 13.0292 10.0232 13.3869 10.2869 13.6506C10.5506 13.9143 10.9083 14.0625 11.2812 14.0625C11.6542 14.0625 12.0119 13.9143 12.2756 13.6506C12.5393 13.3869 12.6875 13.0292 12.6875 12.6562C12.6875 12.2833 12.5393 11.9256 12.2756 11.6619C12.0119 11.3982 11.6542 11.25 11.2812 11.25ZM19.7188 11.25C19.3458 11.25 18.9881 11.3982 18.7244 11.6619C18.4607 11.9256 18.3125 12.2833 18.3125 12.6562C18.3125 13.0292 18.4607 13.3869 18.7244 13.6506C18.9881 13.9143 19.3458 14.0625 19.7188 14.0625C20.0917 14.0625 20.4494 13.9143 20.7131 13.6506C20.9768 13.3869 21.125 13.0292 21.125 12.6562C21.125 12.2833 20.9768 11.9256 20.7131 11.6619C20.4494 11.3982 20.0917 11.25 19.7188 11.25ZM10.6363 17.8125L9.02562 18.75C10.3222 20.9878 12.7316 22.5 15.5 22.5C18.2684 22.5 20.6778 20.9878 21.9744 18.75L20.3638 17.8125C19.8722 18.6678 19.1636 19.3782 18.3096 19.872C17.4556 20.3658 16.4865 20.6256 15.5 20.625C14.5135 20.6256 13.5444 20.3658 12.6904 19.872C11.8364 19.3782 11.1278 18.6678 10.6363 17.8125Z"
                    fill="#ACACAC"
                />
            </svg>
        </div>
    );
};

export const SmileWhite = () => {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="31" height="30" viewBox="0 0 31 30" fill="none">
                <path d="M15.5 3.75C9.29656 3.75 4.25 8.79656 4.25 15C4.25 21.2034 9.29656 26.25 15.5 26.25C21.7034 26.25 26.75 21.2034 26.75 15C26.75 8.79656 21.7034 3.75 15.5 3.75ZM15.5 5.625C20.6891 5.625 24.875 9.81094 24.875 15C24.875 20.1891 20.6891 24.375 15.5 24.375C10.3109 24.375 6.125 20.1891 6.125 15C6.125 9.81094 10.3109 5.625 15.5 5.625ZM11.2812 11.25C10.9083 11.25 10.5506 11.3982 10.2869 11.6619C10.0232 11.9256 9.875 12.2833 9.875 12.6562C9.875 13.0292 10.0232 13.3869 10.2869 13.6506C10.5506 13.9143 10.9083 14.0625 11.2812 14.0625C11.6542 14.0625 12.0119 13.9143 12.2756 13.6506C12.5393 13.3869 12.6875 13.0292 12.6875 12.6562C12.6875 12.2833 12.5393 11.9256 12.2756 11.6619C12.0119 11.3982 11.6542 11.25 11.2812 11.25ZM19.7188 11.25C19.3458 11.25 18.9881 11.3982 18.7244 11.6619C18.4607 11.9256 18.3125 12.2833 18.3125 12.6562C18.3125 13.0292 18.4607 13.3869 18.7244 13.6506C18.9881 13.9143 19.3458 14.0625 19.7188 14.0625C20.0917 14.0625 20.4494 13.9143 20.7131 13.6506C20.9768 13.3869 21.125 13.0292 21.125 12.6562C21.125 12.2833 20.9768 11.9256 20.7131 11.6619C20.4494 11.3982 20.0917 11.25 19.7188 11.25ZM10.6363 17.8125L9.02562 18.75C10.3222 20.9878 12.7316 22.5 15.5 22.5C18.2684 22.5 20.6778 20.9878 21.9744 18.75L20.3638 17.8125C19.8722 18.6678 19.1636 19.3782 18.3096 19.872C17.4556 20.3658 16.4865 20.6256 15.5 20.625C14.5135 20.6256 13.5444 20.3658 12.6904 19.872C11.8364 19.3782 11.1278 18.6678 10.6363 17.8125Z" fill="white"/>
            </svg>
        </div>
    );
};
