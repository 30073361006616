import React, {useRef} from 'react';
import b from './SalePage.module.scss';
import styles from './SalePage.module.scss';
import {Fade} from "react-awesome-reveal";

const CasesReviews = ({onOpenModalCase}) => {

    const scrollRef = useRef(null);

    const scrollLeft = () => {
        const cardWidth = scrollRef.current.querySelector(`.${styles.Card}`).offsetWidth; // Замените cardClassName на класс карточки
        const gap = 20; // Задайте отступ между карточками, если он есть
        scrollRef.current.scrollBy({left: -(cardWidth + gap), behavior: 'smooth'});
    };

    const scrollRight = () => {
        const cardWidth = scrollRef.current.querySelector(`.${styles.Card}`).offsetWidth; // Замените cardClassName на класс карточки
        const gap = 20; // Задайте отступ между карточками, если он есть
        scrollRef.current.scrollBy({left: cardWidth + gap, behavior: 'smooth'});
    };

    const cardData = [
        {id: 1, counter: '1', backgroundImage: 'url(/new/cases/Case.png)'},
        {id: 2, counter: '2', backgroundImage: 'url(/new/cases/Case-1.png)'},
        {id: 3, counter: '3', backgroundImage: 'url(/new/cases/Case-2.png)'},
        {id: 4, counter: '4', backgroundImage: 'url(/new/cases/Case-3.png)'},
        {id: 5, counter: '5', backgroundImage: 'url(/new/cases/Case-4.png)'},
        {id: 6, counter: '6', backgroundImage: 'url(/new/cases/Case-5.png)'},
        {id: 7, counter: '7', backgroundImage: 'url(/new/cases/Case-6.png)'},
        {id: 8, counter: '8', backgroundImage: 'url(/new/cases/Case-7.png)'},
        {id: 9, counter: '9', backgroundImage: 'url(/new/cases/Case-8.png)'},
        {id: 10, counter: '10', backgroundImage: 'url(/new/cases/Case-9.png)'},
        {id: 11, counter: '11', backgroundImage: 'url(/new/cases/Case-10.png)'},
        // { counter: '12', backgroundImage: 'url(/new/cases/Case-11.png)'},
        // { counter: '13', backgroundImage: 'url(/new/cases/Case-12.png)'},
        // { counter: '14', backgroundImage: 'url(/new/cases/Case-13.png)'},
        // { counter: '15', backgroundImage: 'url(/new/cases/Case-14.png)'},
        // { counter: '16', backgroundImage: 'url(/new/cases/Case-15.png)'},
        // { counter: '17', backgroundImage: 'url(/new/cases/Case-16.png)'},
        // { counter: '18', backgroundImage: 'url(/new/cases/Case-17.png)'},
    ];

    return (
        <Fade
            duration="2000"
            className={styles.block7}
        >
            <div className={b.block7}>
                <div className={b.Shape5}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1440" height="730" viewBox="0 0 1440 730"
                         fill="none">
                        <path opacity="0.2"
                              d="M-45.3436 48.3043C-4.1617 15.7538 42.5795 10.4112 91.9928 6.56431C95.6347 6.28079 123.105 2.52376 130.764 7.783C146.617 18.6696 118.193 53.4792 113.836 58.3584C100.326 73.4854 83.1784 84.9049 66.3276 94.6143C63.2413 96.3926 34.8437 106.609 58.9557 95.376C102.687 75.0036 186.004 64.6235 185.234 141.686C184.845 180.608 171.762 213.571 155.2 247.255C130.413 297.669 96.4374 343.308 51.5837 372.779C26.7524 389.094 -9.57443 404.899 -39.2003 401.875C-61.3753 399.612 -72.3568 377.881 -52.9885 358.46C-34.3292 339.749 -0.929817 331.172 22.096 329.211C78.3088 324.424 138.897 336.944 186.599 371.56C252.469 419.361 287.403 531.869 230.012 603.568C210.294 628.201 222.837 663.392 163.267 675.5C134.912 681.263 73.8301 680.362 51.5837 654.905C40.0589 641.716 56.2483 587.747 64.1433 573.557C93.4056 520.965 158.299 493.745 206.394 473.473C349.21 413.275 503.594 381.402 656.355 394.411C751.374 402.502 853.263 420.965 938.946 470.274C979.332 493.515 1028.73 536.002 1029.32 593.666C1029.62 622.855 1011.7 650.372 992.734 667.701C957.809 699.614 903.092 725.848 857.309 724.979C815.562 724.187 783.223 692.969 762.293 654.905C745.754 624.828 727.285 573.088 748.095 539.434C768.04 507.179 805.229 498.709 836.422 490.839C946.542 463.056 1064.29 461.801 1176.49 463.571C1331.07 466.011 1485.34 481.013 1633 533.646"
                              stroke="#6DC6FE" stroke-width="10" stroke-linecap="round"/>
                    </svg>
                </div>

                <div className={b.titleControls}>
                    <h2 className={b.BlockTitles}>Уже получили результат с нами</h2>

                    <div className={b.controls}>
                        <button onClick={scrollLeft} className={b.scrollButton}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="8" viewBox="0 0 28 8"
                                 fill="none">
                                <path d="M28 7H2L7.65217 1" stroke="#6DC6FE" stroke-width="1.5"/>
                            </svg>
                        </button>
                        <button onClick={scrollRight} className={b.scrollButton}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="8" viewBox="0 0 28 8"
                                 fill="none">
                                <path d="M0 7H26L20.3478 1" stroke="#6DC6FE" stroke-width="1.5"/>
                            </svg>
                        </button>
                    </div>
                </div>

                <div className={b.CardWrapper} ref={scrollRef}>
                    {cardData.map((card, index) => (
                        <div key={index} className={b.Card}
                             style={{backgroundImage: card.backgroundImage}}
                             onClick={() => onOpenModalCase(card.id)}
                        >
                        </div>
                    ))}
                </div>

                <div className={styles.MobileControls}>
                    <button onClick={scrollLeft} className={styles.scrollButton}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="8" viewBox="0 0 28 8" fill="none">
                            <path d="M28 7H2L7.65217 1" stroke="#6DC6FE" stroke-width="1.5"/>
                        </svg>
                    </button>
                    <button onClick={scrollRight} className={styles.scrollButton}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="8" viewBox="0 0 28 8" fill="none">
                            <path d="M0 7H26L20.3478 1" stroke="#6DC6FE" stroke-width="1.5"/>
                        </svg>
                    </button>
                </div>
            </div>
        </Fade>
    );
};

export default CasesReviews;
