import axios from 'axios';

// Функция для отправки POST-запроса к API Тинькофф ЭквайрингЦ
async function makePayment(orderId, amount, description) {
    try {
        const response = await axios.post('https://securepay.tinkoff.ru/v2/Init', {
            TerminalKey: '1687867608959',
            Amount: amount * 100, // Сумма в копейках
            OrderId: orderId,
            Description: description,
            SuccessURL: 'https://webinar.telegramatica.com/success',
            FailURL: 'https://webinar.telegramatica.com/404',
        });

        return response.data;
    } catch (error) {
        throw error;
    }
}

export default makePayment;
