import React, { useState, useEffect } from 'react';
import styles from './BurgerMenu.module.scss';
import { Link } from "react-scroll";

const BurgerMenu = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    // Блокируем скролл при открытии модального окна
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isOpen]);

    return (
        <div className={styles.burgerContainer}>
            <div className={styles.header}>
                <div className={styles.burgerIcon} onClick={toggleMenu}>
                    <img src="/burger.png" alt="icon" />
                </div>
            </div>

            {isOpen && (
                <div className={styles.modal}>
                    <div className={styles.modalHeader}>
                        <button onClick={toggleMenu} className={styles.closeButton}>×</button>
                        <div className={styles.logo}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="193" height="13" viewBox="0 0 193 13"
                                     fill="none">
                                    <path d="M5.18688 11.9998V3.8901H0.375V0.847071H14.256V3.8901H9.44413V11.9998H5.18688Z"
                                          fill="white"/>
                                    <path
                                        d="M15.3587 0.847071H27.6957V3.5753H19.556V4.9694H27.5309V7.68264H19.556V9.21165H27.8157V11.9998H15.3587V0.847071Z"
                                        fill="white"/>
                                    <path d="M29.0462 0.847071H33.3034V8.80692H41.0384V11.9998H29.0462V0.847071Z" fill="white"/>
                                    <path
                                        d="M42.1627 0.847071H54.4997V3.5753H46.3599V4.9694H54.3348V7.68264H46.3599V9.21165H54.6196V11.9998H42.1627V0.847071Z"
                                        fill="white"/>
                                    <path
                                        d="M63.3306 12.2097H62.9858C56.8848 12.2097 55.4607 8.94183 55.4607 6.58835V6.16862C55.4607 3.77018 56.7798 0.637207 63.3606 0.637207H63.9452C70.7808 0.637207 71.7102 3.38043 71.7701 4.93942V4.95441H67.333C67.2581 4.6696 66.8683 3.54532 63.7203 3.54532C60.7223 3.54532 59.8678 4.83449 59.8678 6.30354V6.46843C59.8678 7.89251 60.9322 9.28661 63.6904 9.28661C66.5685 9.28661 67.4979 8.25228 67.4979 7.89251H63.5854V5.71892H71.8451V11.9998H69.1618C69.0869 11.6401 68.847 10.8606 68.6372 10.4558C68.2024 10.9355 66.8083 12.2097 63.3306 12.2097Z"
                                        fill="white"/>
                                    <path
                                        d="M77.4691 3.56031V5.47907H82.356C83.3004 5.47907 83.6451 5.05934 83.6451 4.51969V4.5047C83.6451 3.98004 83.3153 3.56031 82.356 3.56031H77.4691ZM77.4691 8.17732V11.9998H73.2119V0.847071H83.3453C86.5982 0.847071 88.0373 1.89639 88.0373 3.71022V3.87511C88.0373 5.49406 86.6282 6.16862 85.7887 6.3635C87.1678 6.67829 88.0823 7.57771 88.0823 9.01678V10.6657C88.0823 11.4452 88.1872 11.73 88.2921 11.9249V11.9998H83.9449C83.84 11.8799 83.81 11.73 83.81 11.4152V10.1111C83.81 8.8369 83.2254 8.17732 81.5465 8.17732H77.4691Z"
                                        fill="white"/>
                                    <path
                                        d="M98.6216 7.41282L96.7478 3.5753L94.919 7.41282H98.6216ZM100.855 11.9998L99.9857 10.201H93.6149L92.7604 11.9998H88.4432L93.9596 0.847071H99.6859L105.517 11.9998H100.855Z"
                                        fill="white"/>
                                    <path
                                        d="M106.003 11.9998V0.847071H111.849L115.267 7.11301L118.745 0.847071H124.351V11.9998H120.139V5.04435L116.227 11.9998H114.053L110.155 5.04435V11.9998H106.003Z"
                                        fill="white"/>
                                    <path
                                        d="M135.014 7.41282L133.14 3.5753L131.311 7.41282H135.014ZM137.248 11.9998L136.378 10.201H130.007L129.153 11.9998H124.836L130.352 0.847071H136.078L141.91 11.9998H137.248Z"
                                        fill="white"/>
                                    <path d="M144.55 11.9998V3.8901H139.738V0.847071H153.619V3.8901H148.807V11.9998H144.55Z"
                                          fill="white"/>
                                    <path d="M158.964 0.847071V11.9998H154.722V0.847071H158.964Z" fill="white"/>
                                    <path
                                        d="M168.496 12.2097H167.912C161.061 12.2097 160.027 8.71697 160.027 6.60334V6.16862C160.027 4.04 160.956 0.637207 167.912 0.637207H168.496C175.272 0.637207 176.261 3.71022 176.261 5.14928V5.34416H171.839C171.749 5.04435 171.404 3.63527 168.166 3.63527C165.093 3.63527 164.434 4.99938 164.434 6.24358V6.42346C164.434 7.62268 165.183 9.19666 168.181 9.19666C171.464 9.19666 171.794 7.62268 171.854 7.36785H176.261V7.60769C176.261 9.06175 175.212 12.2097 168.496 12.2097Z"
                                        fill="white"/>
                                    <path
                                        d="M185.665 7.41282L183.791 3.5753L181.962 7.41282H185.665ZM187.898 11.9998L187.029 10.201H180.658L179.804 11.9998H175.486L181.003 0.847071H186.729L192.56 11.9998H187.898Z"
                                        fill="white"/>
                                </svg>
                            </div>
                            <nav className={styles.nav}>
                                <Link onClick={toggleMenu} to={'tariffs'} smooth={true} duration={500} className={styles.navLink}>
                                    ТАРИФЫ
                                    <svg xmlns="http://www.w3.org/2000/svg" width="196" height="10" viewBox="0 0 196 10"
                                         fill="none">
                                        <path d="M0.5 9H193.5L185.875 1" stroke="white" stroke-width="1.5"/>
                                    </svg>
                                </Link>

                                <Link onClick={toggleMenu} to={'prog'} smooth={true} duration={500} className={styles.navLink}>
                                    ПРОГРАММА
                                    <svg xmlns="http://www.w3.org/2000/svg" width="196" height="10" viewBox="0 0 196 10"
                                         fill="none">
                                        <path d="M0.5 9H193.5L185.875 1" stroke="white" stroke-width="1.5"/>
                                    </svg>
                                </Link>
                            </nav>
                            <Link onClick={toggleMenu} to={'ask'} smooth={true} duration={500} className={styles.questionButton}>ЗАДАТЬ ВОПРОС</Link>
                    </div>
                </div>
            )}
        </div>
    );
};

export default BurgerMenu;
