import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ContactForm from "./ContactForm";
import SuccessPage from "./SuccessPage";
import WebinarPage from "./WebinarPage";
import Error from "./Error";
import SalePage from "./NewSite/SalePage";
import CloseSales from "./CloseSales/CloseSales";
import CloseSalesPay99000 from "./CloseSales/CloseSalesPay99000";
import CloseSalesPay139000 from "./CloseSales/CloseSalesPay139000";
import CloseSalesPay159000 from "./CloseSales/CloseSalesPay159000";
import CloseSalesInstallment99000 from "./CloseSales/CloseSalesInstallment99000";
import CloseSalesInstallment139000 from "./CloseSales/CloseSalesInstallment139000";
import CloseSalesInstallment159000 from "./CloseSales/CloseSalesInstallment159000";
import TEST from "./TEST";
import SalesPage from "./SalesPage";

function App() {
    const subdomain = window.location.hostname.split('.')[0];

    return (
        <Router>
            <Routes>
                <Route path="/success" element={<SuccessPage />} />
                <Route path="/error" element={<Error />} />
                {/* Внутреняя рассрочка */}
                <Route path="/i4urg4u3gfiejwbvkjebiuy1" element={<CloseSales />} />

                {/* Закрытые продажи DONE */}
                {/* Полная оплата курса */}
                <Route path="/close_sales_pay99000" element={<CloseSalesPay99000 />} />
                <Route path="/close_sales_pay139000" element={<CloseSalesPay139000 />} />
                <Route path="/close_sales_pay159000" element={<CloseSalesPay159000 />} />
                {/* Рассрочка */}
                <Route path="/close_sales_installment99000" element={<CloseSalesInstallment99000 />} />
                <Route path="/close_sales_installment139000" element={<CloseSalesInstallment139000 />} />
                <Route path="/close_sales_installment159000" element={<CloseSalesInstallment159000 />} />

                {/* Открытые продажи TODO */}
                {subdomain === 'anketa' && <Route path="/" element={<ContactForm />} />}
                {subdomain === 'webinar' && <Route path="/" element={<WebinarPage />} />}
                {/* По умолчанию отображаем SalePage */}
                <Route path="/*" element={<SalePage />} />
            </Routes>
        </Router>
        // <div className="Service"> UPDATING SOON. Обновляем сайт :) </div>
    );
}

export default App;
