import styles from './SalePage.module.scss';
import {SmileBlue, SmileBlack, SmileWhite} from '../Components/SmileSvg'
import {Fade} from "react-awesome-reveal";

const Tariffs = () => {
    return (
        <Fade
            duration="2000"
            className={styles.wrapper6}
        >
            <div id={'tariffs'} className={styles.wrapper6}>
                <h2 className={styles.BlockTitles}>Тарифы </h2>

                <div className={styles.block6}>
                    {/*LVL1*/}
                    <div className={styles.planCard}>
                        <div className={styles.planLevel}>
                            <p>УРОВЕНЬ 1</p>
                            <h4 className={styles.planTitle}>БАЗА</h4>
                        </div>

                        <div className={styles.Price1}>
                            <p className={styles.planDescription}>Базовый тариф со всеми основными инструментами
                                БЕСПЛАТНОГО и
                                платного продвижения вашего телеграм-канала. 6 недель эффективной работы над вашим
                                первым
                                рекламным бизнесом в телеграме</p>

                            <div className={styles.Line}/>

                            {/*LIST*/}
                            <ul className={styles.featuresList}>
                                {/*on*/}
                                <li className={styles.featureItem}>
                                    <SmileBlue/> 30 часов обучающих лекций онлайн
                                </li>
                                <li className={styles.featureItem}>
                                    <SmileBlue/> Авторские методы набора трафика
                                </li>
                                <li className={styles.featureItem}>
                                    <SmileBlue/> Доступ в общий чат
                                </li>

                                {/*off*/}
                                <li className={styles.featureItemDisabled}>
                                    <SmileBlack/> Обучение созданию сетки телеграм-каналов
                                </li>

                                <li className={styles.featureItemDisabled}>
                                    <SmileBlack/> Дополнительные лекции про продвижение анонимных каналов
                                </li>

                                <li className={styles.featureItemDisabled}>
                                    <SmileBlack/> Комплексный модуль по монетизации
                                </li>

                                <li className={styles.featureItemDisabled}>
                                    <SmileBlack/> Инфоповоды на эксклюзивах
                                </li>

                                <li className={styles.featureItemDisabled}>
                                    <SmileBlack/> Обратная связь с Миланой в чате
                                </li>

                                <li className={styles.featureItemDisabled}>
                                    <SmileBlack/> Лекция эксперта по выгодной продаже каналов
                                </li>

                                <li className={styles.featureItemDisabled}>
                                    <SmileBlack/> Модуль по обучению TG-продажам
                                </li>

                                <li className={styles.featureItemDisabled}>
                                    <SmileBlack/> TG-прогревы
                                </li>

                                <li className={styles.featureItemDisabled}>
                                    <SmileBlack/> TG-запуски
                                </li>

                                <li className={styles.featureItemDisabled}>
                                    <SmileBlack/> Эффективные инфоповоды для повышения продаж на целевую аудиторию
                                </li>

                                <li className={styles.featureItemDisabled}>
                                    <SmileBlack/> TG-воронки продаж
                                </li>
                            </ul>

                            {/*Price*/}
                            <div className={styles.pricing}>
                                <div>
                                    <p className={styles.titlePrice}>В РАССРОЧКУ:</p>
                                    <p className={styles.pricePerMonth}>от 2,857 ₽ / мес.</p>
                                </div>

                                <div>
                                    <p className={styles.titlePrice}>ПОЛНАЯ СТОИМОСТЬ СО СКИДКОЙ:</p>
                                    <p className={styles.pricePerMonth}>69.000 ₽</p>
                                    <span className={styles.priceDiscount}>138,000 ₽</span>
                                </div>
                            </div>

                            <div className={styles.Line}/>

                            <div className={styles.ButtonWrapper}>
                                <a href='https://telegramatica.getcourse.ru/page25'
                                   className={styles.PrimaryButton}>Купить</a>
                                <a href='https://telegramatica.getcourse.ru/page22' className={styles.SecondryButton}>Оформить
                                    рассрочку</a>
                                <a target='_blank' href='https://telegramatica.getcourse.ru/page28'
                                   className={styles.ButtonLink}>Забронировать место</a>
                            </div>
                        </div>
                    </div>

                    {/*LVL2*/}
                    <div className={styles.planCard}>
                        <div className={styles.planLevel}>
                            <p>УРОВЕНЬ 2</p>
                            <h4 className={styles.planTitle}>ПРОДВИНУТЫЙ</h4>
                        </div>

                        <div className={styles.Price1}>
                            <p className={styles.planDescription}>Расширенный тариф с доступом ко всему обучающему
                                материалу по продвижению и монетизации блога</p>

                            <div className={styles.Line}/>

                            {/*LIST*/}
                            <ul className={styles.featuresList}>
                                {/*on*/}
                                <li className={styles.featureItem}>
                                    <SmileBlue/> 40 часов обучающих лекций онлайн-формата с отработкой изученного
                                    материала (материалы с разборами + домашние задания)
                                </li>

                                <li className={styles.featureItem}>
                                    <SmileBlue/> Авторские методы набора трафика
                                </li>

                                <li className={styles.featureItem}>
                                    <SmileBlue/> Доступ в общий чат
                                </li>

                                <li className={styles.featureItem}>
                                    <SmileBlue/> Обучение созданию сетки телеграм-каналов
                                </li>

                                <li className={styles.featureItem}>
                                    <SmileBlue/> Дополнительные лекции про продвижение анонимных каналов
                                </li>

                                <li className={`${styles.featureItem} ${styles.bold}`}>
                                    <SmileBlue/> Комплексный модуль по монетизации
                                </li>

                                <li className={styles.featureItem}>
                                    <SmileBlue/> Инфоповоды на эксклюзивах
                                </li>

                                <li className={`${styles.featureItem} ${styles.bold}`}>
                                    <SmileBlue/> Обратная связь с Миланой в чате
                                </li>

                                <li className={styles.featureItem}>
                                    <SmileBlue/> Лекция эксперта по выгодной продаже каналов
                                </li>

                                {/*off*/}
                                <li className={styles.featureItemDisabled}>
                                    <SmileBlack/> Модуль по обучению TG-продажам
                                </li>

                                <li className={styles.featureItemDisabled}>
                                    <SmileBlack/> TG-прогревы
                                </li>

                                <li className={styles.featureItemDisabled}>
                                    <SmileBlack/> TG-запуски
                                </li>

                                <li className={styles.featureItemDisabled}>
                                    <SmileBlack/> Эффективные инфоповоды для повышения продаж на целевую аудиторию
                                </li>

                                <li className={styles.featureItemDisabled}>
                                    <SmileBlack/> TG-воронки продаж
                                </li>
                            </ul>

                            {/*Price*/}
                            <div className={styles.pricing}>
                                <div>
                                    <p className={styles.titlePrice}>В РАССРОЧКУ:</p>
                                    <p className={styles.pricePerMonth}>от 3,700 ₽ / мес.</p>
                                </div>

                                <div>
                                    <p className={styles.titlePrice}>ПОЛНАЯ СТОИМОСТЬ СО СКИДКОЙ:</p>
                                    <p className={styles.pricePerMonth}>89.000 ₽</p>
                                    <span className={styles.priceDiscount}>178,000 ₽</span>
                                </div>
                            </div>

                            <div className={styles.Line}/>

                            <div className={styles.ButtonWrapper}>
                                <a href='https://telegramatica.getcourse.ru/page26'
                                   className={styles.PrimaryButton}>Купить</a>
                                <a href='https://telegramatica.getcourse.ru/page23' className={styles.SecondryButton}>Оформить
                                    рассрочку</a>
                                <a target='_blank' href='https://telegramatica.getcourse.ru/page28'
                                   className={styles.ButtonLink}>Забронировать место</a>
                            </div>
                        </div>
                    </div>


                    {/*LVL3*/}
                    <div className={styles.planCard}>
                        <div className={styles.planLevel}>
                            <p>УРОВЕНЬ 3</p>
                            <h4 className={styles.planTitle}>ЭКСПЕРТНЫЙ</h4>
                        </div>

                        <div className={`${styles.Price1} ${styles.GOD}`}>
                            <p className={styles.planDescription}>Максимальный тариф с доступом во все обучающие модули.
                                Комплексное обучение по привлечению трафика бесплатными методами в экспертные
                                каналы/блоги для повышения продаж</p>

                            <div className={styles.Line}/>

                            {/*LIST*/}
                            <ul className={styles.featuresList}>
                                {/*on*/}
                                <li className={styles.featureItem}>
                                    <SmileWhite/> 50 часов обучающих лекций онлайн-формата с отработкой изученного
                                    материала (материалы с разборами + домашние задания)
                                </li>

                                <li className={styles.featureItem}>
                                    <SmileWhite/> Авторские методы набора трафика
                                </li>

                                <li className={styles.featureItem}>
                                    <SmileWhite/> Доступ в общий чат
                                </li>

                                <li className={styles.featureItem}>
                                    <SmileWhite/> Обучение созданию сетки телеграм-каналов
                                </li>

                                <li className={styles.featureItem}>
                                    <SmileWhite/> Дополнительные лекции про продвижение анонимных каналов
                                </li>

                                <li className={`${styles.featureItem} ${styles.bold}`}>
                                    <SmileWhite/> Комплексный модуль по монетизации
                                </li>

                                <li className={styles.featureItem}>
                                    <SmileWhite/> Инфоповоды на эксклюзивах
                                </li>

                                <li className={`${styles.featureItem} ${styles.bold}`}>
                                    <SmileWhite/> Обратная связь с Миланой в чате
                                </li>

                                <li className={styles.featureItem}>
                                    <SmileWhite/> Лекция эксперта по выгодной продаже каналов
                                </li>

                                <li className={`${styles.featureItem} ${styles.bold}`}>
                                    <SmileWhite/> Модуль по обучению TG-продажам
                                </li>

                                <li className={`${styles.featureItem} ${styles.bold}`}>
                                    <SmileWhite/> TG-прогревы
                                </li>

                                <li className={`${styles.featureItem} ${styles.bold}`}>
                                    <SmileWhite/> TG-запуски
                                </li>

                                <li className={`${styles.featureItem} ${styles.bold}`}>
                                    <SmileWhite/> Эффективные инфоповоды для повышения продаж на целевую аудиторию
                                </li>

                                <li className={`${styles.featureItem} ${styles.bold}`}>
                                    <SmileWhite/> TG-воронки продаж
                                </li>
                            </ul>

                            {/*Price*/}
                            <div className={styles.pricing}>
                                <div>
                                    <p className={styles.titlePrice}>В РАССРОЧКУ:</p>
                                    <p className={styles.pricePerMonth}>от 4,950 ₽ / мес.</p>
                                </div>

                                <div>
                                    <p className={styles.titlePrice}>ПОЛНАЯ СТОИМОСТЬ СО СКИДКОЙ:</p>
                                    <p className={styles.pricePerMonth}>119.000 ₽</p>
                                    <span className={styles.priceDiscount}>238,000 ₽</span>
                                </div>
                            </div>

                            <div className={styles.Line}/>

                            <div className={styles.ButtonWrapper}>
                                <a href='https://telegramatica.getcourse.ru/page27'
                                   className={styles.PrimaryButton}>Купить</a>
                                <a href='https://telegramatica.getcourse.ru/page24' className={styles.SecondryButton}>Оформить
                                    рассрочку</a>
                                <a target='_blank' href='https://telegramatica.getcourse.ru/page28'
                                   className={styles.ButtonLink}>Забронировать место</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fade>
    );
};

export default Tariffs;
