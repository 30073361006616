import React from 'react';
import c from "../CloseSales/CloseSales.module.scss";

const ButtonForInstallmentKZ = () => {

    const open = () => {
        const url = 'https://form.crm.rrllc.ru/milanapetrova_pf/'; // ваша ссылка
        window.open(url, '_blank');
    }

    return (
        <button className={c.ButtonPrimary} onClick={open}>Рассрочка для Казахстана и РБ</button>
    );
};

export default ButtonForInstallmentKZ;
