import React from 'react';
import c from "../CloseSales/CloseSales.module.scss";

const ButtonForInstallmentLocal = () => {

    const open = () => {
        const url = 'https://form.crm.rrllc.ru/milanapetrova/'; // ваша ссылка
        window.open(url, '_blank');
    }

    return (
        <button className={c.ButtonPrimary} onClick={open}>Сервис онлайн-рассрочки</button>
    );
};

export default ButtonForInstallmentLocal;
