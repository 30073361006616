import React from 'react';
import styles from './SalePage.module.scss';
import {motion} from "framer-motion"
import {Fade} from "react-awesome-reveal";


const ProgrammBlock = ({onOpenModal}) => {
    //DataBase
    const cardData = [
        {id: 1, title: 'Модуль 1', name: 'НИШЕВАНИЕ', backgroundImage: 'url(/new/Mc1.png)'},
        {id: 2, title: 'Модуль 2', name: 'ТРАФИК', backgroundImage: 'url(/new/MC2.png)'},
        {id: 3, title: 'Модуль 3', name: 'НЕЙРОСЕТИ', backgroundImage: 'url(/new/MC3.png)'},
        {
            id: 4,
            title: 'Модуль 4',
            name: 'СОЗДАНИЕ СЕТКИ\n И ПРОДВИЖЕНИЕ\n АНОНИМНЫХ КАНАЛОВ',
            backgroundImage: 'url(/new/MC4.png)'
        },
        {id: 5, title: 'Модуль 5', name: 'МОНЕТИЗАЦИЯ ТЕЛЕГРАМ-КАНАЛОВ', backgroundImage: 'url(/new/MC5.png)'},
        {
            id: 6,
            title: 'Модуль 6',
            name: 'МОНЕТИЗАЦИЯ ЭКСПЕРТНОСТИ\n И ПРОДАЖИ В ТЕЛЕГРАМ',
            backgroundImage: 'url(/new/MC6.png)'
        },
    ];
    //Front
    return (
        <Fade
            duration="2000"
            className={styles.block3}
        >
            <div id={'prog'} className={styles.block3}>
                {/*закарючка*/}
                <div className={styles.bgShape}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="488" height="712" viewBox="0 0 488 712" fill="none">
                        <path opacity="0.2"
                              d="M550.615 172.128C494.124 111.912 428.211 61.1612 342.755 25.6759C325.018 18.3106 286.048 1.66531 275.982 20.8723C267.556 36.9526 283.519 58.1024 298.216 72.3191C307.375 81.1786 338.264 100.127 335.707 89.7308C330.726 69.4822 281.585 45.1223 262.51 36.0611C221.721 16.6854 170.417 -1.22422 127.415 7.74738C74.1262 18.8652 52.6385 64.3211 52.2059 105.61C51.4913 173.798 97.1965 242.182 147.386 303.531C167.223 327.779 188.436 351.561 211.22 374.504C231.384 394.809 293.701 452.532 328.492 468.707C389.575 497.106 404.973 438.01 401.016 408.34C391.734 338.724 322.197 283.218 241.147 247.325C177.7 219.228 75.8687 190.387 29.0066 234.421C-21.4409 281.824 18.6489 364.653 65.3157 418.06C138.176 501.444 250.192 566.171 351.59 627.269C396.34 654.233 441.703 679.986 487.187 706.027"
                              stroke="#6DC6FE" stroke-width="10" stroke-linecap="round"/>
                    </svg>
                </div>

                <h2 className={styles.BlockTitles}>Программа</h2>

                <div className={styles.ProgrammWrapper}>
                    {cardData.map((card, index) => (
                        <div key={index} className={styles.ModuleCard} style={{backgroundImage: card.backgroundImage}}>
                            <p className={styles.ModuleCardTitle}>{card.title}</p>
                            <p className={styles.ModuleCardName}>{card.name}</p>

                            <button className={styles.ModuleCardButton} onClick={() => onOpenModal(card.id)}>
                            </button>
                        </div>
                    ))}
                </div>
            </div>
        </Fade>
    )
        ;
};

export default ProgrammBlock;
