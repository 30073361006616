import React, {useRef} from 'react';
import styles from './SalePage.module.scss';
import {Fade} from "react-awesome-reveal";

const Block2 = () => {

    const scrollRef = useRef(null);

    const scrollLeft = () => {
        const cardWidth = scrollRef.current.querySelector(`.${styles.Card}`).offsetWidth; // Замените cardClassName на класс карточки
        const gap = 20; // Задайте отступ между карточками, если он есть
        scrollRef.current.scrollBy({left: -(cardWidth + gap), behavior: 'smooth'});
    };

    const scrollRight = () => {
        const cardWidth = scrollRef.current.querySelector(`.${styles.Card}`).offsetWidth; // Замените cardClassName на класс карточки
        const gap = 20; // Задайте отступ между карточками, если он есть
        scrollRef.current.scrollBy({left: cardWidth + gap, behavior: 'smooth'});
    };


    const cardData = [
        {
            counter: '1',
            title: 'Для новичков, кто хочет создать успешный telegram — канал, но не знает, с чего начать:',
            description: 'Мы поможем вам запустить свой первый канал в Telegram, даже если у вас нет опыта! Освоите бесплатные методы для привлечения тысяч подписчиков и начнёте зарабатывать на рекламе.',
            backgroundImage: 'url(/new/Card-1.png)'
        },
        {
            counter: '2',
            title: 'Для экспертов, которые хотят увеличить доход от своих продуктов и услуг:',
            description: 'Вы научитесь продвигать свои продукты и услуги в Telegram, привлекать бесплатно новый трафик и кратно увеличите сумму запуска.',
            backgroundImage: 'url(/new/Card-2.png)'
        },
        {
            counter: '3',
            title: 'Для тех, кто давно мечтает стать блогером, но до сих пор не может начать:',
            description: 'Мы научим вас привлекать аудиторию и создавать контент, который разлетается по всем соцсетям. Вы обучитесь нашей авторской «Формуле создания вирусного контента» и стратегиям для роста без вложений в рекламу, используя только органические методы!',
            backgroundImage: 'url(/new/Card-3.png)'
        },
        {
            counter: '4',
            title: 'Для тех, кто хочет построить бизнес и создать сетку каналов:',
            description: 'Мы покажем, как развивать успешную сетку Telegram-каналов. Вы научитесь управлять несколькими каналами, монетизировать их через рекламу и привлекать сотни тысяч подписчиков, создавая масштабный и стабильный бизнес.',
            backgroundImage: 'url(/new/Card-4.png)'
        },
        {
            counter: '5',
            title: 'Для тех, кто хочет быть экспертом и привлекать клиентов на автопилоте:',
            description: 'Вы научитесь создавать цепляющий контент и привлекать клиентов с помощью авто-воронок.',
            backgroundImage: 'url(/new/Card-5.png)'
        },
        {
            counter: '6',
            title: 'Для тех, кто мечтает о свободе и переезде в другую страну:',
            description: 'Наше обучение откроет вам путь к заработку онлайн, что даст возможность жить и работать из любой точки мира.',
            backgroundImage: 'url(/new/Card-6.png)'
        },
        {
            counter: '7',
            title: 'Для тех, кто устал от рутинной работы и мечтает о стабильном доходе онлайн:',
            description: 'Мы научим вас строить бизнес на социальных сетях, зарабатывать через продажу рекламы и привлекать постоянный поток рекламодателей.',
            backgroundImage: 'url(/new/Card-7.png)'
        },
        {
            counter: '8',
            title: 'Для будущих инфлюенсеров, которые хотят стать медийными:',
            description: 'Мы научим вас создавать узнаваемый личный бренд и быть медийным, чтобы о вас говорили и вам доверяли.',
            backgroundImage: 'url(/new/Card-8.png)'
        },
        {
            counter: '9',
            title: 'Для будущих экспертов, которые хотят создать свой первый продукт, но не знают, с чего начать:',
            description: 'Наше обучение поможет вам разработать продукт с нуля и эффективно продвигать его через социальные сети. \n' +
                'Вы освоите все шаги — от идеи до продаж, привлекая заинтересованную аудиторию и зарабатывая на своём опыте.',
            backgroundImage: 'url(/new/Card-9.png)'
        },
    ];

    return (
        <Fade
            duration="3000"
            className={styles.block2}
        >
            <div className={styles.block2}>
                <div className={styles.titleControls}>
                    <h2 className={styles.BlockTitles}>Наше обучение для вас, если </h2>
                    <div className={styles.controls}>
                        <button onClick={scrollLeft} className={styles.scrollButton}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="8" viewBox="0 0 28 8"
                                 fill="none">
                                <path d="M28 7H2L7.65217 1" stroke="#6DC6FE" stroke-width="1.5"/>
                            </svg>
                        </button>
                        <button onClick={scrollRight} className={styles.scrollButton}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="8" viewBox="0 0 28 8"
                                 fill="none">
                                <path d="M0 7H26L20.3478 1" stroke="#6DC6FE" stroke-width="1.5"/>
                            </svg>
                        </button>
                    </div>
                </div>

                <div className={styles.CardWrapper} ref={scrollRef}>
                    {cardData.map((card, index) => (
                        <div key={index} className={styles.Card}
                             style={{backgroundImage: card.backgroundImage}}>
                            <div className={styles.Counter}>[{card.counter}]</div>
                            <div className={styles.Title}>{card.title}</div>
                            <div className={styles.descriptionWrapper}>
                                <p className={styles.description}>{card.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
                <div className={styles.MobileControls}>
                    <button onClick={scrollLeft} className={styles.scrollButton}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="8" viewBox="0 0 28 8" fill="none">
                            <path d="M28 7H2L7.65217 1" stroke="#6DC6FE" stroke-width="1.5"/>
                        </svg>
                    </button>
                    <button onClick={scrollRight} className={styles.scrollButton}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="8" viewBox="0 0 28 8" fill="none">
                            <path d="M0 7H26L20.3478 1" stroke="#6DC6FE" stroke-width="1.5"/>
                        </svg>
                    </button>
                </div>
            </div>
        </Fade>
    );
};

export default Block2;
