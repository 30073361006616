import React, {useEffect} from 'react';
import styles from './Modal.module.scss';
import {Fade} from "react-awesome-reveal";

const ModalWindow = ({moduleId, onClose}) => {
    const ModalData = [
        {
            id: 1,
            cards: [
                {
                    number: '01',
                    title: 'Telegram как платформа для роста и дохода',
                    text: 'Создадите или улучшите свой Telegram-канал: освоите ключевые функции, настройки и стратегии для активного роста и стабильного заработка.'
                },

                {
                    number: '02',
                    title: 'Безопасность Telegram-аккаунта',
                    text: 'Когда канал начинает расти, он привлекает не только подписчиков, но и тех, кто захочет его украсть. Мы разберём ключевые меры безопасности, чтобы вы защитили свой бизнес: от настройки двойной верификации до правильного распределения админ-прав. Telegram — это бизнес, и вы научитесь его защищать.'
                },

                {
                    number: '03',
                    title: 'Нишевание и тематика',
                    text: 'В этой лекции мы поможем каждому найти наиболее подходящую и прибыльную нишу для развития. \n' +
                        'Для тех, у кого ниша уже выбрана,проверим её актуальность и поможем упаковать так, чтобы усилить привлекательность и доходность канала.'
                },

                {
                    number: '04',
                    title: 'Создание контента',
                    text: 'Узнаете, какой контент лучше всего подходит для разных ниш и тематик. В результате вы будете понимать, что публиковать, чтобы привлекать и удерживать свою аудиторию!'
                },

                {
                    number: '05',
                    title: 'Контент и инфоповоды',
                    text: 'Научитесь создавать интересные и креативные посты, освоите правила публикации контента для своей ниши и начинаем изучать базу и фундамент создания вертикального контента. \n' +
                        'А также, начинаем изучать правила создания контент-плана. В результате — готовый двухмесячный контент-план и фундаментальные знания для создания контента.'
                },

                {
                    number: '06',
                    title: 'Создание инфоповода',
                    text: 'Вы узнаете о различных типах инфоповодов и освоите шаблоны для их создания. В результате вы разработаете 4 инфоповода, которые сможете внедрить сразу же в контент-план и получить первые тысячи или даже миллионы просмотров.'
                },
            ],
            module: 'ДОСТУПЕН ВО ВСЕХ ТАРИФАХ',

            bonusTitle: 'БОНУС 1 МОДУЛЯ',
            bonus: ['Список популярных ниш и их  особенности',
                'Чек-лист по безопасности Telegram-аккаунта',
                'Список идей для контента по разным тематикам',
                'Рабочие промты для создания контент-плана с помощью ChatGPT'
            ]
        },

        {
            id: 2,
            cards: [
                {
                    number: '01',
                    title: 'TikTok как инструмент для трафика',
                    text: 'Вы узнаете, как работает платформа TikTok и какие возможности она предоставляет для привлечения трафика. Результат — полное понимание того, как использовать TikTok'
                },

                {
                    number: '02',
                    title: 'TikTok: создание контента и перегон трафика',
                    text: 'Научитесь правилам ведения и создания контента на TikTok, освоите наш авторский метод «Формула создания вирусного контента» для эффективного перегона трафика в Telegram. На этом этапе вы уже начнёте привлекать тысячи новых подписчиков.'
                },

                {
                    number: '03',
                    title: 'Reels. Приглашенный эксперт',
                    text: 'Вы погрузитесь в основы работы с Reels, освоите правила создания контента и поиска клиентов через Instagram. Узнаете, как правильно упаковывать рилсы, которые привлекают миллионы просмотров!'
                },

                {
                    number: '04',
                    title: 'YouTube Shorts',
                    text: 'Вы узнаете, каким нишам подходит YouTube Shorts, и почему анонимным каналам очень эффективно привлекать подписчиков из YouTube.Освоите правила ведения Shorts,разберём все форматы и как на них зарабатывать. В итоге вы сможете успешно направлять использовать воронку трафика в Telegram.'
                },

                {
                    number: '05',
                    title: 'Как быть узнаваемым: раскрытие личности и уникальных фишек',
                    text: 'Научитесь создавать интересные и креативные посты, освоите правила публикации контента для своей ниши и начинаем изучать базу и фундамент создания вертикального контента. А также, начинаем изучать правила создания контент-плана. В результате — готовый двухмесячный контент-план и фундаментальные знания для создания контента.'
                },

                {
                    number: '06',
                    title: 'Как стать узнаваемым: индивидуальность, медийность',
                    text: 'Вы научитесь раскрывать свою уникальность, создавать цепляющие фишки и работать над медийностью.А если вы эксперт, сформируете узнаваемый личный бренд.В результате — ваш блог станет популярным и легко узнаваемым для аудитории!'
                },
            ],
            module: 'ДОСТУПЕН ВО ВСЕХ ТАРИФАХ',

            bonusTitle: 'БОНУС 2 МОДУЛЯ',
            bonus: ['Формула создания вирусного контента',
                'Чек-лист для создания узнаваемого личного бренда',
                'Креативные идеи для раскрытия индивидуальности'
            ]
        },

        {
            id: 3,
            cards: [
                {
                    number: '01',
                    title: 'Создание и клонирование голоса с помощью Eleven Labs',
                    text: 'Вы узнаете, как с помощью нейросети создавать и клонировать любой голос. Освоите инструменты для создания креативного контента и анонимных проектов, которые можно успешно монетизировать!'
                },

                {
                    number: '02',
                    title: 'Создание фото-контента с помощью нейросетей',
                    text: 'Научитесь создавать любые фотографии и изображения с помощью нейросетей! Освоите инструменты для генерирования креативного контента в ваш блог, или погружающего материала в ваш продукт. Нейросети будут работать за вас.'
                },

                {
                    number: '03',
                    title: 'Создание видео-контента',
                    text: 'Вы научитесь превращать любые изображения в видео, создавая уникальные ролики для блога. Этот навык позволит вам креативно продвигать свои продукты и услуги, делая контент более привлекательным и вовлекающим!'
                },

                {
                    number: '04',
                    title: 'Повышаем продажи с помощью ChatGPT',
                    text: 'Вы узнаете, как использовать ChatGPT для увеличения продаж: от написания продающих текстов, постов — до анализа аудитории и создания воронок продаж.'
                },
            ],
            module: 'ДОСТУПЕН ВО ВСЕХ ТАРИФАХ',

            bonusTitle: 'БОНУС 3 МОДУЛЯ',
            bonus: ['Пошаговый гайд по использованию Eleven Labs',
                'Справочник нейросетей для создания контента',
                'Шаблоны контент-плана для анонимных проектов — примеры, как можно креативно и эффективно вести канал, не раскрывая личность',
                'Промты ChatGPT для повышения продаж'
            ]
        },

        {
            id: 4,
            cards: [
                {
                    number: '01',
                    title: 'Боты в Telegram',
                    text: 'Вы познакомитесь с полезными ботами для Telegram, узнаете, как с их помощью продавать рекламу и собственные продукты, а также какие боты использовать для создания контента. В результате вы сможете настроить нужных ботов для вашего канала.'
                },

                {
                    number: '02',
                    title: 'Рекламные стратегии и воронки трафика в Telegram',
                    text: 'Вы узнаете, какие виды рекламы работают в Telegram, изучите базовые шаблоны и стратегии для создания эффективных рекламных макетов. В результате вы создадите собственный рекламные макет, который будет работать и привлекать подписчиков.'
                },

                {
                    number: '03',
                    title: 'Креатив в рекламе и настройка воронок трафика',
                    text: 'Вы изучите различные рекламные форматы, разберётесь в их стоимости и поймёте, как определить актуальность рекламы. Научитесь выстраивать успешную воронку трафика между вашими каналами, чтобы перегнать максимум подписчиков и создать успешную сетку каналов.'
                },
            ],
            module: 'ДОСТУПЕН В ТАРИФАХ: УРОВЕНЬ 2/3',

            bonusTitle: 'БОНУС 4 МОДУЛЯ',
            bonus: ['Чек-лист для настройки рекламных ботов',
                'Шаблон рекламного макета',
                'Калькулятор стоимости рекламы в Telegram, чтобы вы самостоятельно смогли рассчитать',
                'Примеры успешных креативных рекламных кампаний'
            ]
        },

        {
            id: 5,
            cards: [
                {
                    number: '01',
                    title: 'Продажа первой рекламы в Telegram',
                    text: 'Вы узнаете, как подготовить привлекательное описание для рекламодателей, определить оптимальное количество подписчиков для старта, а также в каких каналах лучше покупают рекламу. Поймёте, где искать первых рекламодателей и успешно продадите свою первую рекламу!'
                },

                {
                    number: '02',
                    title: 'Работа с рекламодателями: кейсы и стратегии',
                    text: 'Вы узнаете, как выстраивать эффективное сотрудничество с рекламодателями, разберёте кейсы Миланы и получите экспертные рекомендации. Рассмотрим плюсы и минусы разных шаблонов и стратегий работы, чтобы вы могли выбрать оптимальный подход для вашего канала!'
                },

                {
                    number: '03',
                    title: 'Командообразование для Telegram-бизнеса',
                    text: 'Вы узнаете, кто необходим для успешного ведения бизнеса в Telegram, от редактора до специалистов для сетки каналов. Изучите, где искать команду, как выстраивать командные процессы и получите первых сотрудников в вашу команду!'
                },

                {
                    number: '04',
                    title: 'Работа с менеджерами по рекламе: делегируем продажу рекламы',
                    text: 'Вы узнаете, как эффективно взаимодействовать с менеджерами по рекламе, где найти менеджера и как правильно с ними работать. В бонус вы получите полный список секретных чатов для продажи рекламы.'
                },
            ],
            module: 'ДОСТУПЕН В ТАРИФАХ: УРОВЕНЬ 2/3',

            bonusTitle: 'БОНУС 5 МОДУЛЯ',
            bonus: ['Бесплатная команда редакторов для вашего канала!',
                'Список секретных чатов'
            ]
        },

        {
            id: 6,
            cards: [
                {
                    number: '01',
                    title: 'Варианты монетизации экспертности, поиск востребованного продукта',
                    text: 'Вы узнаете, как превратить свои знания и опыт в доход, разбирая различные варианты продуктов и услуг для монетизации.'
                },

                {
                    number: '02',
                    title: 'Инфобизнес — основа',
                    text: 'Вы получите базу знаний по запуску инфопродуктов: освоите декомпозицию, планирование доходов и расходов, чтобы избежать убытков. А также мы проработаем продуктовую матрицу, и текущие ваши продукты/услуги.'
                },

                {
                    number: '03',
                    title: 'Прогрев',
                    text: 'Вы узнаете ключевые приёмы продаж в блоге,или канале, этапы эффективного прогрева и триггеры, которые продают.'
                },

                {
                    number: '04',
                    title: 'Воронки продаж и их организация',
                    text: 'Мы научим вас,которые будут генерировать прибыль вашему продукту. Мы построим систему, способную продавать за вас, и разберём все текущие ваши воронки.'
                },

                {
                    number: '05',
                    title: 'Техническая подготовка: приём оплат и платформы',
                    text: 'Вы узнаете, как настроить все технические аспекты приёма оплат и выбрать подходящие платформы, чтобы старт продаж прошёл без стресса и финансовых потерь. В результате — гладкий запуск и уверенность в работе всех систем!'
                },
            ],
            module: 'ЭКСКЛЮЗИВНО ДЛЯ: УРОВЕНЬ 3',

            bonusTitle: 'БОНУС 6 МОДУЛЯ',
            bonus: ['Чек-лист по созданию автоворонок',
                'Шаблоны продающих триггеров ',
                'Инструкция по декомпозиции и планированию запусков'
            ]
        },
    ];

    const data = ModalData.find(item => item.id === moduleId);

    if (!data) return null;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        // Блокируем прокрутку основного контента
        document.body.style.overflow = 'hidden';

        // Возвращаем стиль при размонтировании компонента
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);

    if (!data) return null;

    return (
        <Fade
            duration="800"
            className={styles.modalOverlay}
        >
        <div className={styles.modalOverlay}>
            <div className={styles.modalContent}>
                {/*закарючка*/}
                <div className={styles.bgShape}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="333" height="700" viewBox="0 0 333 700"
                         fill="none">
                        <path opacity="0.2"
                              d="M84.6086 746.363C48.0248 741.831 31.2355 722.407 14.896 701.059C13.6917 699.486 3.34148 688.349 5.39169 682.395C9.6356 670.068 44.5463 667.619 49.5404 667.45C65.0234 666.927 78.5863 669.778 90.7306 673.264C92.9548 673.902 108.61 682.554 93.3152 676.364C65.5747 665.138 34.9769 630.902 95.497 596.44C126.064 579.035 155.392 570.253 186.221 562.77C232.364 551.571 277.247 546.822 312.411 554.461C331.878 558.69 354.046 568.521 359.671 583.728C363.881 595.111 349.836 610.061 329.412 609.788C309.735 609.525 294.013 597.796 286.267 587.924C267.358 563.824 260.813 529.86 275.038 491.932C294.681 439.558 373.312 372.399 444.907 366.815C469.505 364.896 493.664 343.134 519.208 365.495C531.367 376.139 547.138 405.083 533.215 426.98C526.002 438.324 479.396 455.146 466.161 457.87C417.107 467.963 378.299 449.943 349.46 436.633C263.823 397.11 197.234 339.384 166.21 262.135C146.912 214.086 133.878 158.14 149.357 95.828C156.653 66.4574 176.581 24.1812 221.551 -2.15108C244.316 -15.4805 270.686 -19.541 289.363 -18.5111C323.761 -16.6143 359.052 -2.90435 370.721 18.8784C381.362 38.7408 397.663 39.4917 359.671 88.9629C340.593 110.28 312.411 129.963 275.038 140.463C244.414 145.719 224.5 149.463 193.108 134.435C127.5 103.028 108.916 41.0969 80.0374 -12.123C40.2485 -85.4485 10.3762 -164.304 11.7404 -257.072"
                              stroke="#6DC6FE" stroke-width="10" stroke-linecap="round"/>
                    </svg>
                </div>
                <button onClick={onClose} className={styles.closeButton}>×</button>

                <div className={styles.FlexWrapper}>
                    <div className={styles.CardsWrapper}>
                        {data.cards.map((card, index) => (
                            <div key={index} className={styles.card}>
                                <div className={styles.header}>
                                    <p className={styles.number}>[ {card.number} ]</p>
                                    <p className={styles.cardTitle}>{card.title}</p>
                                </div>
                                <p className={styles.cardDescription}>{card.text}</p>
                            </div>
                        ))}

                    </div>
                    <div className={styles.bonusList}>
                        <div className={styles.BonusCard}>
                            <h4 className={styles.bonusTitle}>{data.bonusTitle}</h4>
                            <div className={styles.list}>
                                {data.bonus.map((item, bonusIndex) => (
                                    <li key={bonusIndex}>{item}</li>
                                ))}
                            </div>
                            <div className={styles.Line}/>
                            <h4 className={styles.bonusTitle}>Разбор ваших домашних заданий на онлайн-вебинаре:</h4>
                        </div>
                    </div>
                </div>

                <div className={styles.star}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M4.03997 10.0001L0 7.66571L2.97768 2.50993L7.02 4.84028L7.02228 0.175537H12.9777L12.98 4.84028L17.0223 2.50993L20 7.66571L15.96 10.0001L20 12.3345L17.0223 17.4903L12.98 15.1599L12.9777 19.8247H7.02228L7.02 15.1599L2.97768 17.4903L0 12.3345L4.03997 10.0001Z"
                              fill="#6DC6FE"/>
                    </svg>
                    <h2>{data.module}</h2>
                </div>
            </div>
        </div>
        </Fade>
    );
};

export default ModalWindow;
