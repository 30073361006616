import React from 'react';
import s from "../SalePage.module.scss";
import a from "../Form.module.scss";
import style from '../NewSite/SalePage.module.scss'
import {Fade} from "react-awesome-reveal";

const Footer1 = () => {
    return (
        <Fade
            duration="2000"
            className={s.footer}
        >
            <div className={s.footer}>

                <div className={s.docs}>
                    <p>TELEGRAMATIСA" LLC <br/>
                        Տելեգրամատիկա ՍՊԸ <br/>
                        ИНН 02896366</p>

                    <p className={s.center}>
                        ООО «ТЕЛЕГРАМАТИКА» <br/>
                        ИНН 02896366
                    </p>

                    <div className={s.Contacts}>
                        <p>Контакты:</p>
                        <a href="mailto:info@milanapetrova.com" target="_blank">E-mail: info@milanapetrova.com</a>
                        <a href="https://t.me/pashastayhome1" target="_blank">Онлайн-менеджер в Телеграм</a>
                    </div>
                </div>

                <div className={style.Line}/>

                <div className={s.docs}>
                    <a href="/оферта ИП Петров.pdf" download>Публичная оферта</a>

                    <a className={s.center}
                       href="https://docs.google.com/document/d/15kN3LLS7bSILgrHK48ebNE1qwHdRe5NL/edit" target="_blank">
                        Политика обработки персональных данных
                    </a>

                    <a className={s.right}
                       href="https://docs.google.com/document/d/1rmTGQ_SJAsZ7CnswKfVvhPKjz3ZAppKn/edit" target="_blank">Согласие
                        на обработку персональных данных</a>
                </div>
            </div>
        </Fade>
    );
};

export default Footer1;
