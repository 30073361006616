import React, {useState} from 'react';
import styles from './SalePage.module.scss';
import ReactPhoneInput from 'react-phone-input-2';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
import {Fade} from "react-awesome-reveal";

const SupportBlock = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        fullName: '',
        phoneNumber: '',
        telegram: '',
    });
    const navigate = useNavigate();

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true);

            const response = await axios.post('/api/send-email', formData);

            if (response.status === 200) {
                console.log('Данные успешно отправлены');
                setIsLoading(false);
                navigate('/success');
            } else {
                console.error('Произошла ошибка при отправке данных');
                setIsLoading(false);
            }
        } catch (error) {
            console.error('Произошла ошибка', error);
            setIsLoading(false);
        }
    };

    return (
        <Fade
            duration="2000"
            className={styles.block4}
        >
            <div id={'ask'} className={styles.SupportBlockWrapper}>
                <form className={styles.SupportBlock} onSubmit={handleSubmit}>
                    <div className={styles.Text}>
                        <h3>Поможем решить <br/> все вопросы</h3>
                        <p>Если вы хотите больше узнать об обучении или не знаете, какую программу обучения выбрать,
                            оставьте заявку — и мы перезвоним</p>
                    </div>
                    <div className={styles.Form}>
                        <input
                            placeholder="ИМЯ"
                            type="text"
                            id="fullName"
                            name="fullName"
                            value={formData.fullName}
                            onChange={handleChange}
                            required
                        />
                        <div className={styles.InputWrapper}>
                            <div className={styles.phoneWrapperInput}>
                                <ReactPhoneInput
                                    inputProps={{
                                        name: 'phoneNumber',
                                        required: true,
                                        className: 'phoneInput',
                                        placeholder: 'ТЕЛЕФОН',
                                    }}
                                    country={'ru'}
                                    value={formData.phoneNumber}
                                    onChange={(value) =>
                                        setFormData({...formData, phoneNumber: value})
                                    }
                                />
                            </div>

                            <input
                                placeholder="TELEGRAM"
                                type="text"
                                id="telegram"
                                name="telegram"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className={styles.FormButtonWrapper}>
                            <button className={`${styles.PrimaryButton} ${styles.hight}`} type="submit"
                                    disabled={isLoading}>
                                {isLoading ? 'Отправка...' : 'Отправить'}
                            </button>
                            <p>Нажимая на кнопку, вы соглашаетесь на обработку <br/> персональных данных и с правилами
                                пользования платформой</p>
                        </div>
                    </div>
                </form>
            </div>
        </Fade>
    );
};

export default SupportBlock;
