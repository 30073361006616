import React, {useEffect} from 'react';
import styles from './Modal.module.scss';
import {Fade} from "react-awesome-reveal";

const ModalWindowCase = ({caseId, onClose}) => {
    const ModalData = [
        {
            id: 1,
            info: [
                {
                    // Аватарка = ID просто число в конце изменить
                    avatar: 'url(/new/cases/A1.png)',
                    name: 'ФРИКАДЕЛИ - ученица 1го потока',
                    tg: '@fricadeli',
                    description: 'Наша ученица выбрала правильно новостную нишу и полностью с нуля, на обучении ТЕЛЕГРАМАТИКА впервые завела телеграм-канал.',
                },
            ],
            // Какие-то поля могут быть пустыми
            graySmile: [],
            blueSmile: [
                '10 тыс. подписчиков на анонимном канале без лица',
                '123 тыс. подписчиков на YouTube Shorts',
                '35 тыс. подписчиков в Tik-Tok',
                'Миллионы просмотров',
                'Активно продаёт рекламу в своём канале'
            ]
        },

        {
            // И так далее
            id: 2,
            info: [
                {
                    // Аватарка = ID просто число в конце изменить
                    avatar: 'url(/new/cases/A2.png)',
                    name: 'ОХ - ученик 1го потока',
                    tg: '@theohdaily',
                },
            ],
            // Какие-то поля могут быть пустыми
            // gray серая галочка в blue синяя
            graySmile: [
                'Вели ранее успешный паблик в ВК, не знали как продвигать Телеграм',
                '7 тыс. подписчиков в канале, нет стратегии развития канала, не продают рекламу',
                'Не имеют источников бесплатного трафика'
            ],
            blueSmile: [
                '<strong> C 7 тыс до 225 тыс. подписчиков в канале абсолютно бесплатно</strong>, четкая стратегия продвижения, УСПЕШНЫЙ БРЕНД',
                '148 тыс. подписчиков в Tik-Tok',
                'Постоянные <strong>рекламодатели<strong>',
                'От 500 тысяч руб/месяц на продаже рекламы'
            ]
        },

        {
            id: 3,
            info: [
                {
                    avatar: 'url(/new/cases/A3.png)',
                    name: 'Федя Фетисов - ученик 2го потока',
                    tg: '@queersdad',
                },
            ],
            graySmile: [
                'Более года Телеграм-канал не рос по подписчикам. На обучении создал громкий инфоповод и собрал более 13 млн просмотров в общем за неделю.',
                '14 тыс. подписчиков, не имеет навыка создания инфоповода',
            ],
            blueSmile: [
                '24 тыс. подписчиков, чёткая стратегия продвижения через контент',
                'После «Телеграматика» сделал запуск на 1,5 млн ₽ Самый успешный запуск'
            ]
        },

        {
            id: 4,
            info: [
                {
                    avatar: 'url(/new/cases/A4.png)',
                    name: 'саша секретничает - ученик 1го потока',
                    tg: '@rkhmanov1',
                },
            ],
            // Какие-то поля могут быть пустыми
            // gray серая галочка в blue синяя
            graySmile: [],
            blueSmile: [
                'На обучении завёл с нуля TikTok с помощью «формулы создания инфоповода», набрал сразу миллионы просмотров и более 8 тыс. в TikTok',
                'Пригнал в канал более 6 тыс. подписчиков'
            ]
        },

        {
            id: 5,
            info: [
                {
                    avatar: 'url(/new/cases/A5.png)',
                    name: 'МАКАРОВА ПРО КОНТЕНТ - ученица 1го потока',
                    tg: '@makarova_pro_kontent',
                    description: 'Наша ученица Алиса, на обучении выбрала экспертную нишу и начала продвигать свои услуги по Маркетингу.',
                },
            ],
            graySmile: [],
            blueSmile: [
                '2800 потенциальных клиентов',
                'Собирает миллионы просмотров на YouTube',
                'Запустила свое обучение по маркетингу',
                'Запустила свой подкаст на YouTube',
                'Активно продает услуги в своем канале'
            ]
        },

        {
            id: 6,
            info: [
                {
                    avatar: 'url(/new/cases/A6.png)',
                    name: 'Влад Корнация - ученик 1го потока',
                    tg: '@cornaciatarot',
                    description: 'Владислав на обучении вырос до 36.000 подписчиков в пике.',
                },
            ],
            graySmile: [],
            blueSmile: [
                'Запустил онлайн школу по эзотерике',
                'Зарабатывает 4000 евро в месяц',
                'Переехал в Испанию',
                'Активно продает услуги в своем канале'
            ]
        },

        {
            id: 7,
            info: [
                {
                    avatar: 'url(/new/cases/A7.png)',
                    name: 'ТРИГГЕР - ученик 1го потока',
                    tg: '@triggernew',
                },
            ],
            graySmile: [],
            blueSmile: [
                'Полностью с нуля создал канал на обучении',
                'Бесплатными методами пригнал 10к подписчиков в пике',
                'Стабильная продажа рекламы',
                'Сотни тысяч просмотров в Tik-Tok'
            ]
        },

        {
            id: 8,
            info: [
                {
                    avatar: 'url(/new/cases/A8.png)',
                    name: 'SyK - ученик 1го потока',
                    tg: '@syk',
                },
            ],
            graySmile: [
                'Админ тг-каналов, который ранее использовал только платное продвижение'
            ],
            blueSmile: [
                'Начал использовать бесплатные методы и продвигать свой канал на инфоповодах',
                '52.0000 и 28.000 подписчиков в каналах',
                'Создал сетку каналов и зарабатывает на продаже каналов',
                'Постоянная продажа рекламы'
            ]
        },

        {
            id: 9,
            info: [
                {
                    avatar: 'url(/new/cases/A9.png)',
                    name: 'Maniloun - ученица 3го потока',
                    tg: '@maniloun',
                },
            ],
            graySmile: [
                'До обучения застряла в просмотрах на вертикальном контенте',
                'Не эффективно продавала рекламу в канале'
            ],
            blueSmile: [
                'YouTube Блогер',
                'На обучении — обучилась снимать вирусный контент и набрала 5 млн просмотров',
                'Новая стратегия правильной продажи рекламы'
            ]
        },

        {
            id: 10,
            info: [
                {
                    avatar: 'url(/new/cases/A10.png)',
                    name: 'СВИТ',
                    tg: '@Sweetsosquahub',
                },
            ],
            graySmile: [
                'Админ канала о Dota 2, 2 года вел канал и не мог вырасти',
                'Аудитория до обучения 2700 подписчиков'
            ],
            blueSmile: [
                'После обучения 31000 подписчиков',
                'Применил стратегии продвижения через инфоповоды'
            ]
        },

        {
            id: 11,
            info: [
                {
                    avatar: 'url(/new/cases/A11.png)',
                    name: 'Матвей - ученик 1го потока',
                    tg: '@badassonly',
                },
            ],
            graySmile: [
                'Матвей развивался в Tik-Tok, но подписчики в канале стояли на месте больше года'
            ],
            blueSmile: [
                'На обучение Матвей пришел с 1500 подписчиков и за 2 недели привлек 5000 подписчиков',
                'На обучении продал первую рекламу',
                'Раскачал просмотры до сотни тысяч'
            ]
        },
    ];

    const data = ModalData.find(item => item.id === caseId);

    if (!data) return null;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        // Блокируем прокрутку основного контента
        document.body.style.overflow = 'hidden';

        // Возвращаем стиль при размонтировании компонента
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);

    if (!data) return null;

    return (
        <Fade
            duration="800"
            className={styles.modalOverlay}
        >
            <div className={styles.modalOverlay}>
                <div className={`${styles.modalContent} ${styles.CaseMOdal}`}>
                    {/*закарючка*/}
                    <button onClick={onClose} className={styles.closeButton}>×</button>

                    <div className={styles.FlexWrapperCase}>
                        {data.info.map((card, index) => (
                            <div className={styles.CardsWrapperCase}>
                                <div className={styles.headerCase}>
                                    <div style={{backgroundImage: card.avatar}} className={styles.avatar}/>
                                    <p className={styles.name}>[ {card.name} ]</p>
                                    <p className={styles.tg}>{card.tg}</p>
                                </div>
                                <div className={styles.cardCase}>
                                    <p className={styles.dsc}>{card.description}</p>

                                    <div className={styles.list}>
                                        {data.graySmile.map((item, bonusIndex) => (
                                            <div className={styles.CaseList}>
                                                <img src="./new/cases/gray.png" alt=""/>
                                                <p key={bonusIndex}>{item}</p>
                                            </div>
                                        ))}
                                    </div>
                                    <div className={styles.list}>
                                        {data.blueSmile.map((item, bonusIndex) => (
                                            <div className={styles.CaseList}>
                                                <img src="./new/cases/blue.png" alt=""/>
                                                <p key={bonusIndex}>{item}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                </div>
            </div>
        </Fade>
    );
};

export default ModalWindowCase;
