import React, {useState} from 'react';
import styles from './SalePage.module.scss'
import {Fade} from "react-awesome-reveal";

const Block4 = () => {
    const tabs = [
        {
            id: 'curators',
            label: 'Опытные кураторы',
            backgroundImage: 'url(/new/01.jpeg)',
            title: 'Даём подробную\n' +
                'обратную связь',
            text: 'Каждую вашу работу проверит куратор-эксперт.\n' +
                'Он расскажет, что можно улучшить,\n' +
                'и объяснит сложное простыми словами.'
        },

        {
            id: 'psychologist',
            label: 'Психолог',
            backgroundImage: 'url(/new/02.jpeg)',
            title: 'Поддержка психолога',
            text: 'В нашей команде работает опытный психолог, который поддерживает\n вас на пути к вашим целям. Она помогает справиться с тревогой\n перед созданием первого канала или блога, поддерживает во время\n запусков и помогает не бросить на полпути и избежать выгорания.\n' +
                '\n' +
                'Психологические консультации помогают укрепить уверенность, найти\n мотивацию и преодолеть любые эмоциональные барьеры, которые могут\n возникнуть в процессе обучения и роста. \n' +
                '\n' +
                'Мы знаем как важна поддержка.'
        },
        {
            id: 'practice',
            label: 'Практика',
            backgroundImage: 'url(/new/03.jpeg)',
            title: 'Теория и практика — сразу\n в действия!',
            text: 'После каждой лекции вас ждут практические задания,\n которые позволят сразу же применить полученные знания\n и закрепить материал. \n' +
                '\n' +
                'На каждом этапе кураторы будут рядом, поддерживая\n вас и помогать с любыми вопросами выполнения\n домашних заданий.'
        },
        {
            id: 'community',
            label: 'Комьюнити',
            backgroundImage: 'url(/new/004.jpeg)',
            title: 'TELEGRAMATICA — не просто\n школа, а комьюнити',
            text: 'Наши студенты становятся частью комьюнити,\n где находят единомышленников и друзей.\n' +
                '\n' +
                'Вас ждёт много творчества, вдохновения\n и постоянной поддержки.\n' +
                'Обучайтесь в комфортной и безопасной атмосфере,\n где каждый шаг приближает вас к успеху!'
        }
    ];

    // Состояние для выбранного таба
    const [activeTab, setActiveTab] = useState(tabs[0].id);

    // Находим данные для активного таба
    const activeContent = tabs.find(tab => tab.id === activeTab);

    return (
        <Fade
            duration="2000"
            className={styles.block4}
        >
            <div className={styles.block4}>
                <h2 className={styles.BlockTitles}>Помогаем и сопровождаем <br/> на пути к успеху</h2>

                <div className={styles.Gap}>
                    {/* Вкладки */}
                    <div className={styles.TabBlocks}>
                        <div className={styles.tabs}>
                            {tabs.map(tab => (
                                <button
                                    key={tab.id}
                                    className={`${styles.tabButton} ${activeTab === tab.id ? styles.active : ''}`}
                                    onClick={() => setActiveTab(tab.id)}
                                >
                                    {tab.label}
                                </button>
                            ))}
                        </div>

                        {/* Блок с изменяемым контентом */}
                        <div className={styles.contentBlock}>
                            <div className={styles.Text}>
                                <h2>{activeContent.title}</h2>
                                <p>{activeContent.text}</p>
                            </div>
                            <div style={{backgroundImage: activeContent.backgroundImage}}
                                 className={styles.ImageBlock}/>
                        </div>

                        {/*Mobile*/}
                        <div className={styles.contentBlockMobile}
                             style={{backgroundImage: activeContent.backgroundImage}}>
                            <div className={styles.TextBlock}>
                                <h2>{activeContent.title}</h2>
                                <p>{activeContent.text}</p>
                            </div>
                        </div>
                    </div>

                    {/* Три блока с заглушками */}
                    <div className={styles.placeholderBlocks}>

                        <div className={styles.placeholder}>
                            <div className={styles.imgPlaceholder}>
                                <img className={styles.imgPlaceholder} src="/new/shape3.png" alt="shape1"/>
                            </div>
                            <h3>Свободный график</h3>
                            <p>Обучение легко совмещать <br/>
                                с работой и личной жизнью</p>
                        </div>

                        <div className={styles.placeholder}>
                            <div className={styles.imgPlaceholder}>
                                <img className={styles.imgPlaceholder} src="/new/shape1.png" alt="shape2"/>
                            </div>
                            <h3>Длинный доступ
                                к обучению</h3>
                            <p>Лекции доступны до 6 месяцев <br/> и вы сможете всегда повторно пройти <br/> материал</p>
                        </div>

                        <div className={styles.placeholder}>
                            <div className={styles.imgPlaceholder}>
                                <img className={styles.imgPlaceholder} src="/new/shape2.png" alt="shape3"/>
                            </div>
                            <h3>Методические материалы с вами навсегда</h3>
                            <p>Все презентации и методические материалы <br/> остаются у вас навсегда</p>
                        </div>
                    </div>
                </div>
            </div>
        </Fade>
    );
};

export default Block4;
